<template>
  <div
    style=""
    class="absolute bottom-5 top-0 left-0 z-30 bg-white shadow pr-4 pl-6 pt-5 w-full h-full"
  >
    <div
      class="text-darkblue flex justify-between text-xl font-bold w-full whitespace-no-wrap font-SourceSansPro"
    >
      <span>
        {{
          title === 1
            ? $t('templateCommandTranslater.add')
            : $t('templateCommandTranslater.edit')
        }}</span
      >
      <div class="flex justify-end col-start-2 items-center">
        <i
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
          @click="$emit('close')"
        >
        </i>
      </div>
    </div>
    <div class="mt-9 mb-2 relative">
      <span
        class="text-annotationColor text-sm font-SourceSansPro font-semibold"
      >
        {{ $t('templateCommandTranslater.typeTermial') }}
      </span>
      <div class="flex mt-4 justify-between">
        <el-select
          :class="[isTypeMissing ? 'input-error' : '']"
          style="width: 65%"
          class="mr-4"
          v-model="command.terminal_type"
          filterable
        >
          <el-option
            v-for="(item, index) in terminal_type"
            :key="index"
            :value="item.key"
            :label="item.value"
          />
        </el-select>
        <div class="flex justify-end">
          <skif-button @click="createOrEditTemplate">
            {{ $t('btn.save') }}
          </skif-button>
        </div>
      </div>
      <transition name="el-zoom-in-top">
        <span
          v-if="isTypeMissing"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ $t('templateCommandTranslater.selectTypeTerminal') }}
        </span>
      </transition>
    </div>
    <div class="mt-9 mb-2 relative">
      <span
        class="text-annotationColor text-sm font-SourceSansPro font-semibold"
      >
        {{ $t('templateCommandTranslater.nameCommand') }}
      </span>
      <div class="flex mt-4 justify-between">
        <el-input
          :class="[isCommandMissing ? 'input-error' : '']"
          style="width: 65%"
          v-model="command.name"
        ></el-input>

        <el-dropdown @command="chooseCommandType" trigger="click">
          <span
            class="relative text-darkblue text-sm font-lato pr-1 font-bold cursor-pointer el-dropdown-link"
          >
            {{ $t('templateCommandTranslater.selectCommand') }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :command="item"
              v-for="(item, index) in command_type"
              :key="index"
            >
              <span>{{ item.value }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <transition name="el-zoom-in-top">
          <span
            v-if="isCommandMissing"
            class="text-notify text-xs top-full left-0 absolute"
          >
            {{ $t('templateCommandTranslater.enterNameCommand') }}
          </span>
        </transition>
      </div>
    </div>
    <div class="mt-9 mb-2 relative">
      <span
        class="text-annotationColor text-sm font-SourceSansPro font-semibold"
      >
        {{ $t('templateCommandTranslater.textCommand') }}
      </span>
      <div class="flex mt-4 justify-between">
        <el-input
          style="width: 65%"
          class="mr-4"
          :class="[isTemplateMissing ? 'input-error' : '']"
          v-model="command.command_template"
          @input="handlText"
        />
      </div>
      <transition name="el-zoom-in-top">
        <span
          v-if="isTemplateMissing"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ $t('templateCommandTranslater.enterTextCommand') }}
        </span>
      </transition>
    </div>
    <div class="mt-9 mb-2">
      <span
        class="text-annotationColor text-sm font-SourceSansPro font-semibold"
      >
        {{ $t('templateCommandTranslater.parametrsCommand') }}
      </span>
      <transition name="slide-fade" mode="out-in">
        <div class="flex mt-4 justify-between">
          <span
            style="width: 95%; word-wrap: break-word"
            class="mr-4 font-bold"
          >
            {{ currentText }}
          </span>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { adminPanel } from '@/api'

export default {
  props: ['title', 'currentcommand'],
  data() {
    return {
      command: {
        terminal_type: '',
        name: '',
        command_template: ''
      },
      currentText: '',
      isTypeMissing: false,
      isCommandMissing: false,
      isTemplateMissing: false
    }
  },
  computed: {
    ...mapState('dictionary', {
      terminal_type: (state) => state.terminal_type,
      command_type: (state) => state.command_type
    })
  },
  watch: {
    'command.terminal_type': function (val) {
      this.isTypeMissing = val === ''
      this.isTypeWrong = false
    },
    'command.name': function (val) {
      this.isCommandMissing = val === ''
    },
    'command.command_template': function (val) {
      this.isTemplateMissing = val === ''
    }
  },

  methods: {
    chooseCommandType(val) {
      if (!this.containsObject(val.key, this.command_type)) {
        this.command.command_template = this.currentText = `{${val.key}}`
      } else {
        this.command_type.forEach((command) => {
          if (command.key === val.key) {
            this.command.name = command.value
            this.command.command_template = `{${command.value}}`
            this.currentText = `${command.value}`
          }
        })
      }
    },
    validation() {
      let isError = false
      if (!this.command.terminal_type) {
        this.isTypeMissing = true
        isError = !this.command.terminal_type.length
      }
      if (!this.command.name) {
        this.isCommandMissing = true
        isError = !this.command.name.length
      }
      if (!this.command.command_template) {
        this.isTemplateMissing = true
        isError = !this.command.command_template.length
      }

      return isError
    },
    createOrEditTemplate() {
      if (!this.validation()) {
        if (this.title === 1) {
          adminPanel.addCommandTemplate(this.command, (response) => {
            this.$emit('close')
          })
        } else {
          const command = {
            terminal_type: this.command.terminal_type,
            name: this.command.name,
            command_template: this.command.command_template,
            id: this.command.id
          }

          adminPanel.editCommandTemplate(command, (response) => {
            this.$emit('close')
          })
        }
      } else {
        console.log('there is error')
      }
    },

    containsObject(obj, list) {
      return list.some((elem) => elem.key === obj)
    },

    handleCommandChange(val) {
      if (!this.containsObject(val, this.command_type)) {
        this.command.command_template = this.currentText = `{${val}}`
      } else {
        this.command_type.forEach((command) => {
          if (command.key === val) {
            this.command.command_template = `{${command.value}}`
            this.currentText = `{${command.value}}`
          }
        })
      }
    },

    handlText(val) {
      const reg = /\{(.*?)\}/g
      const currentText = val.match(reg)
      const formattedText =
        currentText && currentText.length
          ? currentText.map((text) => text.slice(1, -1))
          : ''

      this.currentText =
        currentText && currentText.length ? formattedText.join(',') : ''
    }
  },
  mounted() {
    if (this.title === 2) {
      this.command = this.currentcommand
      this.currentText = this.command.params.join(',')
    }
    // this.title === 2
    //   ? await adminPanel.getRetranslator(
    //       this.item.id,
    //       (response) => {
    //         const item = response.data
    //         this.companies.forEach((val) => {
    //           if (val.name === this.item.company_name) {
    //             this.company = val.id
    //           }
    //         })
    //         if (!this.company) {
    //           this.companies.push({
    //             id: this.item.company_id,
    //             name: this.item.company_name,
    //           })
    //           this.company = this.item.company_id
    //         }
    //         this.retranslator = item
    //         console.log(this.retranslator)
    //         this.isFormReady = true
    //       },
    //       (error) => {
    //         this.$showError(error.response.data.message)
    //       }
    //     )
    //   : ((this.retranslator = {}), (this.isFormReady = true))
  }
}
</script>
